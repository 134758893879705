import gql from "graphql-tag"

export const ADD_ORDER = gql`
    mutation createPSushiOrderShopDev(
        $createpsushiordershopdevinput: CreatePSushiOrderShopDevInput!
    ) {
        createPSushiOrderShopDev(input: $createpsushiordershopdevinput) {
            clientPhone
            id
            clientAddr
            clientComment
            clientEmail
            clientName
            clientNIP
            createdAt
            discounts
            orders
            price
            priority
            status
            deliveryType
            paymentType
            indicativeDate
        }
    }
`
