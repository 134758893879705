import Joi from "joi"

const schema = Joi.object({
    phoneNumber: Joi.string()
        .required()
        .messages({
            "string.empty": "Do uzupełnienia",
            "any.required": "Do uzupełnienia",
        }),

    email: Joi.string()
        .email({ minDomainSegments: 2, tlds: { deny: [] } })
        .required()
        .messages({
            "string.empty": "Do uzupełnienia",
            "any.required": "Do uzupełnienia",
            "string.email": "Podaj właściwy mail",
        }),

    name: Joi.string()
        .required()
        .messages({
            "string.empty": "Do uzupełnienia",
            "any.required": "Do uzupełnienia",
        }),

    nip: Joi.string().allow(""),

    indicativeDate: Joi.string()
        .required()
        .custom((value, helpers) => {
            const tokenizedDate = value.split("/")
            const [day, month, year] = tokenizedDate
            const date = new Date(year, month - 1, day, 23, 59, 59)
            if (new Date() > date) return helpers.error("date.min")
            return value
        })
        .messages({
            "date.min": "Data nie może być z przeszłości",
            "string.empty": "Do uzupełnienia",
            "any.required": "Do uzupełnienia",
        }),

    comment: Joi.string().allow(""),

    address: Joi.string()
        .required()
        .messages({
            "any.required": "Do uzupełnienia",
            "string.empty": "Do uzupełnienia",
        }),

    orders: Joi.array().allow(),

    paymentType: Joi.string()
        .required()
        .messages({
            "any.required": "Do uzupełnienia",
            "string.empty": "Do uzupełnienia",
        }),

    deliveryType: Joi.string()
        .required()
        .messages({
            "any.required": "Do uzupełnienia",
            "string.empty": "Do uzupełnienia",
        }),
})

export const validate = data => schema.validate(data, { abortEarly: false })
