import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { Box } from "src/styled"

const Input = styled.input`
    border-radius: 5px;
    border: 2px solid ${({ theme }) => theme.colors.lightGray};
    padding: 10px;
    width: 100%;
    font-size: 14px;
    margin-top: 10px;

    :focus {
        border: 2px solid ${({ theme }) => theme.colors.primary};
    }
`

const Label = styled.label`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.primaryDark};
`

const Error = styled.small`
    color: red;
`

let autoComplete

const loadScript = (url, callback) => {
    let script = document.createElement("script")
    script.type = "text/javascript"

    if (script.readyState) {
        script.onreadystatechange = function() {
            if (
                script.readyState === "loaded" ||
                script.readyState === "complete"
            ) {
                script.onreadystatechange = null
                callback()
            }
        }
    } else {
        script.onload = () => callback()
    }

    script.src = url
    document.getElementsByTagName("head")[0].appendChild(script)
}

function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        {
            types: ["address"],
            componentRestrictions: { country: "pl" },
        }
    )
    autoComplete.setFields([
        "address_components",
        "formatted_address",
        "street_number",
        "floor",
    ])
    autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery)
    )
}

async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace()
    const query = addressObject.name
        .split(",")
        .slice(0, -1)
        .join()
    updateQuery(prevState => ({ ...prevState, ["address"]: query }))
}

function SearchLocationInput({
    label,
    value,
    onChange,
    setQuery,
    error,
    type = "text",
    ...rest
}) {
    const autoCompleteRef = useRef(null)

    useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_API_KEY}&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef)
        )
    }, [])

    return (
        <div className="search-location-input">
            <Box mt={10} width={1}>
                <Label> {label} </Label>
                <Input
                    ref={autoCompleteRef}
                    type={type}
                    value={value}
                    onChange={onChange}
                    {...rest}
                />
                {error && <Error color={"danger"}> {error} </Error>}
            </Box>
        </div>
    )
}
export default SearchLocationInput
