import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faSquare } from "@fortawesome/free-solid-svg-icons"
import { DateInput, Input, Loading } from "src/components/common"
import { TextArea } from "src/components/common/Input"
import useForm from "src/hooks/useForm"
import { navigate } from "@reach/router"
import {
    Flex,
    Header,
    Container,
    Text,
    LinkButton,
    Grid,
    Box,
} from "src/styled"
import { useSelector, useDispatch } from "react-redux"
import { useMutation } from "react-apollo"
import { ADD_ORDER } from "src/api/graphql/mutations"
import { validate } from "src/modules/summary/validate"
import { statusTypes } from "src/constants"
import { clearBasket } from "src/redux/basket"
import SearchLocationInput from "src/components/common/SearchLocationInput"

const deliveryTypes = {
    pickUp: "Pickup",
    address: "Zone A",
}

const paymentTypes = {
    card: "Card",
    cash: "Cash",
    online: "Online",
}

const FontIcons = styled(FontAwesomeIcon)`
    transition: 0.2s ease-in-out;
    color: gray;
    cursor: pointer;
    margin-right: 10px;
    :hover {
        color: #cccccc;
    }
`

const Label = styled.label`
    font-size: 20px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.primaryDark};
`
const TODAY = new Date()

export default () => {
    const { userData } = useSelector(state => state.user)
    const { handleChange, state, setState, errors: inputErrors } = useForm(
        userData,
        {
            indicativeDate: Date.now(),
        }
    )
    const basket = useSelector(state => state.basket)
    const [addOrder] = useMutation(ADD_ORDER)
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const {
        deliveryType,
        paymentType,
        phoneNumber,
        comment,
        email,
        address,
        name,
        nip,
        indicativeDate,
    } = state
    console.log({ state })

    useEffect(() => {
        if (basket.items.length < 1) {
            navigate("/")
        }
        setState(userData)
    }, [])

    const getDeliveryIcon = option =>
        state.deliveryType === option ? faCheck : faSquare

    const getPaymentIcon = option =>
        state.paymentType === option ? faCheck : faSquare

    const formatDate = dateStr => {
        if (!dateStr) {
            return ""
        }
        let [year, month, day] = dateStr.split("-")
        return `${day}/${month}/${year}`
    }

    const parseDateToInputFormat = dateStr => {
        console.log({ dateStr })
        if (!dateStr) {
            return ""
        }
        let [day, month, year] = dateStr.split("/")
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    }

    const handleDateChange = event => {
        const formattedDate = formatDate(event.target.value)
        setState({
            ...state,
            [event.target.name]: formattedDate,
        })
    }

    function validateState(state) {
        const result = validate(state)
        if (!result.error) {
            setErrors({})
            return true
        }

        const errors = {}
        result.error.details.forEach(detail => {
            const key = detail.context.key
            const message = detail.message
            errors[key] = message
        })
        setErrors(errors)
        console.log(errors)
        return false
    }

    function generateID(length = 9) {
        const charset =
            "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
        let retVal = ""

        for (let i = 0, n = charset.length; i < length; ++i) {
            if (i === 4) retVal += "-"
            else {
                retVal += charset.charAt(Math.floor(Math.random() * n))
            }
        }
        return retVal
    }

    function stringifyList(list) {
        const strigifiedList = []
        list.forEach(item => strigifiedList.push(JSON.stringify(item)))
        return strigifiedList
    }

    function extractItems(items) {
        return items.reduce((allItems, orderItem) => {
            const { quantity, ...itemData } = orderItem
            for (let i = 0; i < quantity; i++) {
                allItems.push(itemData)
            }

            return allItems
        }, [])
    }

    async function onSubmit() {
        if (basket.items.length < 1) {
            navigate("/")
            return
        }

        // CHECK THIS PART WHY IS NOT PASSING VALIDATION!!!
        if (!validateState(state)) {
            return
        }

        setIsLoading(true)
        try {
            await addOrder({
                variables: {
                    createpsushiordershopdevinput: {
                        id: generateID(),
                        clientPhone: phoneNumber,
                        deliveryType,
                        paymentType,
                        clientAddr: address,
                        clientComment: JSON.stringify({
                            client: comment || "-",
                            production: "-",
                        }),
                        clientEmail: email,
                        clientName: name,
                        clientNIP: nip,
                        createdAt: Date.now(),
                        discounts: 0,
                        orders: stringifyList(extractItems(basket.items)),
                        price: basket.totalPrice,
                        priority: false,
                        lastStatus: statusTypes.newOnline,
                        indicativeDate: indicativeDate,
                        status: [
                            JSON.stringify({
                                timestamp: Date.now(),
                                status: statusTypes.newOnline,
                            }),
                        ],
                    },
                },
            })
            dispatch(clearBasket())
            navigate("payment")
        } catch (error) {
            console.error(error)
        }
    }

    if (basket.items.length < 1) {
        navigate("/")
    }

    return (
        <Container>
            <Header
                fontSize={{ md: "xxxl", _: "xl" }}
                textAlign={{ md: "left", _: "center" }}
            >
                Wysyłka i płatność
            </Header>
            <Container>
                <Grid
                    gridColumnGap={20}
                    gridRowGap={{ _: 5, lg: 0 }}
                    gridTemplateColumns={{ _: "1fr", lg: "1fr 1fr" }}
                >
                    <Input
                        name="phoneNumber"
                        value={state.phoneNumber}
                        error={inputErrors.phoneNumber}
                        onChange={handleChange}
                        label={"Numer telefonu*"}
                        placeholder={
                            "Na ten numer zadzwonimy w celu ustalenia godziny zamówienia…"
                        }
                    />
                    <Input
                        name="name"
                        error={errors.name}
                        value={state.name}
                        onChange={handleChange}
                        label={"Nazwa klienta*"}
                        placeholder={" Imię i nazwisko lub nazwa firmy…"}
                    />
                    <Input
                        name="email"
                        value={state.email}
                        error={errors.email}
                        onChange={handleChange}
                        label={"Adres mailowy*"}
                        placeholder={"Tu wyślemy potwierdzenie zamówienia"}
                    />
                    <Input
                        name="nip"
                        value={state.nip}
                        error={errors.nip}
                        onChange={handleChange}
                        label={"NIP"}
                        placeholder={"Numer NIP firmy..."}
                    />
                    {/* Indicative date select */}
                    <DateInput
                        name="indicativeDate"
                        onChange={handleDateChange}
                        error={errors.indicativeDate}
                        label={"Preferowana data zamówienia*"}
                        value={parseDateToInputFormat(state.indicativeDate)}
                        min={`${TODAY.getFullYear()}-${TODAY.getMonth() +
                            1}-${TODAY.getDate()}`}
                    />

                    {/* Address */}
                    <SearchLocationInput
                        name="address"
                        setQuery={setState}
                        value={state.address}
                        error={errors.address}
                        onChange={handleChange}
                        disabled={
                            state.deliveryType === deliveryTypes.pickUp
                                ? true
                                : false
                        }
                        label={"Adres zamówienia*"}
                        placeholder={"Wpisz adres do dostawy..."}
                    />

                    {/* Payment type */}
                    <Flex flexDirection={"column"}>
                        <Label> Rodzaj płatności</Label>

                        <Flex
                            justifyContent={"space-between"}
                            height={"100%"}
                            alignItems="center"
                        >
                            <Text>
                                <FontIcons
                                    size={"lg"}
                                    icon={getPaymentIcon(paymentTypes.cash)}
                                    onClick={() =>
                                        setState(prevState => ({
                                            ...prevState,
                                            paymentType: paymentTypes.cash,
                                        }))
                                    }
                                />
                                Gotówka
                            </Text>

                            <Text>
                                <FontIcons
                                    size={"lg"}
                                    icon={getPaymentIcon(paymentTypes.card)}
                                    onClick={() =>
                                        setState(prevState => ({
                                            ...prevState,
                                            paymentType: paymentTypes.card,
                                        }))
                                    }
                                />
                                Karta przy odbiorze
                            </Text>

                            <Text>
                                <FontIcons
                                    size={"lg"}
                                    icon={getPaymentIcon(paymentTypes.online)}
                                    onClick={() =>
                                        setState(prevState => ({
                                            ...prevState,
                                            paymentType: paymentTypes.online,
                                        }))
                                    }
                                />
                                Online
                            </Text>
                        </Flex>

                        {errors.paymentType && (
                            <Text as="small" color="danger">
                                {errors.paymentType}
                            </Text>
                        )}
                    </Flex>

                    {/* Delivery */}
                    <Flex flexDirection={"column"}>
                        <Label> Sposób odbioru </Label>
                        <Flex
                            justifyContent={"space-between"}
                            height={"100%"}
                            alignItems="center"
                        >
                            <Text>
                                <FontIcons
                                    size={"lg"}
                                    onClick={() =>
                                        setState(prevState => ({
                                            ...prevState,
                                            deliveryType: deliveryTypes.address,
                                            address: deliveryTypes.address
                                                ? address
                                                : "",
                                        }))
                                    }
                                    icon={getDeliveryIcon(
                                        deliveryTypes.address
                                    )}
                                />
                                Dostawa
                            </Text>
                            <Text>
                                <FontIcons
                                    size={"lg"}
                                    onClick={() =>
                                        setState(prevState => ({
                                            ...prevState,
                                            deliveryType: deliveryTypes.pickUp,
                                            address: "Kolejowa 45/U10",
                                        }))
                                    }
                                    icon={getDeliveryIcon(deliveryTypes.pickUp)}
                                />
                                Odbiór osobisty (Kolejowa 45/U10)
                            </Text>
                        </Flex>

                        {errors.deliveryType && (
                            <Text as="small" color="danger">
                                {errors.deliveryType}
                            </Text>
                        )}
                    </Flex>
                    <Box />
                    {/* Comment */}
                    <Flex>
                        <TextArea
                            name="comment"
                            error={errors.comment}
                            value={state.comment}
                            onChange={handleChange}
                            label={"Komentarz"}
                            placeholder={"Komentarz..."}
                        />
                    </Flex>
                </Grid>

                <Flex justifyContent={"flex-end"} mt={50}>
                    <Box></Box>
                    <LinkButton variant={"primary-reverse"} to={"/basket"}>
                        Wstecz
                    </LinkButton>

                    <LinkButton
                        variant={"primary"}
                        as="span"
                        onClick={onSubmit}
                    >
                        {isLoading ? <Loading color="white" /> : "Koniec"}
                    </LinkButton>
                </Flex>
            </Container>
        </Container>
    )
}
